import React, { useMemo, useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Grid,
  Snackbar,
} from "@mui/material";
import { postData } from "../../../api";
import CommonSection from "../../common/section/CommonSection";

const QuestionSection: React.FC = () => {
  const [name, setName] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [type, setType] = useState<any>("comment");
  const [feedback, setFeedback] = useState<any>("");
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const isActive = useMemo(() => {
    return !!name && !!email && !!type && !!feedback;
  }, [name, email, type, feedback]);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setType(event.target.value);
  };
  const format = () => {
    setName("");
    setEmail("");
    setType("comment");
    setFeedback("");
  };
  const createFeedback = () => {
    setOpen(false);
    const data = {
      user_name: name,
      user_email: email,
      type: type,
      feedback: feedback,
      date: new Date(),
    };
    if (name !== "" && email !== "" && type !== "" && feedback !== "") {
      setLoading(true);
      postData("feedback/create", data)
        .then((res) => {
          if (res) {
            setTimeout(() => {
              setLoading(false);
              setOpen(true);
              format();
            }, 2000);
          }
        })
        .catch((err) => {
          setLoading(false);
          throw err;
        });
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <CommonSection id="question" title="ご意見・お問い合わせ">
      {loading && (
        <CircularProgress
          size={68}
          sx={{
            position: "fixed",
            top: "40%",
            left: "50%",
            zIndex: 1,
          }}
        />
      )}
      <div className="bg-white-dark">
        <div className="grid grid-cols-12 items-center">
          <div className="col-span-12 sm:col-span-2 md:col-span-1">
            <img
              src="img/1.png"
              className="mx-auto w-[75px] md:w-[150px] h-auto"
              alt="police"
            ></img>
          </div>
          <div className="col-span-12 sm:col-span-10 md:col-span-11 flex justify-center md:justify-start">
            <h1 className="!text-xs md:!text-xl lg:!text-2xl !font-bold text-[#3e2723] text-center">
              サービス向上のため、ご意見をお聞かせください。
            </h1>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-6 mt-4 sm:mt-0 py-2 sm:py-6">
          <div className="col-span-5 flex justify-between items-center">
            <h1 className="text-left !font-bold !text-[9px] sm:!text-xs md:!text-lg lg:!text-xl text-brown-default">
              氏名
            </h1>
            <div className="!w-[30px] md:!w-auto text-[6px] md:text-sm py-1 md:py-2 px-1 md:px-4 border-[1px] md:border-2 text-[#fff] !border-[#fff] !rounded-lg md:!rounded-xl bg-blue-default">
              必須
            </div>
          </div>
          <div className="col-span-7">
            {/* <TextField
              value={name}
              variant="outlined"
              onChange={(e) => setName(e.target.value)}
              className="!h-[30px] sm:!h-auto bg-[#fff] w-full"
            /> */}
            <input
              className="border-slate-200 placeholder-slate-400 contrast-more:border-slate-400 contrast-more:placeholder-slate-500 w-full p-1 sm:p-2"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
        <div className="grid grid-cols-12 gap-6 py-2 sm:py-6 ">
          <div className="col-span-5 flex justify-between items-center">
            <h1 className="text-left !font-bold !text-[9px] sm:!text-xs md:!text-lg lg:!text-xl text-brown-default">
              メールアドレス
            </h1>
            <div className="!w-[30px] md:!w-auto text-[6px] md:text-sm py-1 md:py-2 px-1 md:px-4 border-[1px] md:border-2 text-[#fff] !border-[#fff] !rounded-lg md:!rounded-xl bg-blue-default">
              必須
            </div>
          </div>
          <div className="col-span-7">
            <input
              className="border-slate-200 placeholder-slate-400 contrast-more:border-slate-400 contrast-more:placeholder-slate-500 w-full p-1 sm:p-2"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="grid grid-cols-12 gap-6 py-2 sm:py-6">
          <div className="col-span-5 flex justify-between items-center">
            <h1 className="text-left !font-bold !text-[9px] sm:!text-xs md:!text-lg lg:!text-xl text-brown-default">
              お問合せカテゴリ
            </h1>
            <div className="!w-[30px] md:!w-auto text-[6px] md:text-sm py-1 md:py-2 px-1 md:px-4 border-[1px] md:border-2 text-[#fff] !border-[#fff] !rounded-lg md:!rounded-xl bg-blue-default">
              必須
            </div>
          </div>
          <div className="col-span-7">
            <div className="h-full sm:flex sm:flex-wrap sm:gap-6 sm:items-center">
              {[
                {
                  value: "comment",
                  label: "ご意見",
                },
                {
                  value: "request",
                  label: "ご要望",
                },
                {
                  value: "report",
                  label: "不具合報告",
                },
              ].map((_, i) => (
                <div key={i} className="text-left flex items-center m-1">
                  <input
                    id={_.value}
                    type="radio"
                    value={_.value}
                    checked={type === _.value}
                    onChange={handleRadioChange}
                    className="sm:scale-150 sm:m-1"
                  />
                  <label htmlFor={_.value} className="text-xs sm:text-lg">
                    {_.label}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-6 py-2 sm:py-6">
          <div className="col-span-5 flex justify-between items-center">
            <h1 className="text-left !font-bold !text-[9px] sm:!text-xs md:!text-lg lg:!text-xl text-brown-default">
              お問い合わせ内容
            </h1>
            <div className="!w-[30px] md:!w-auto text-[6px] md:text-sm py-1 md:py-2 px-1 md:px-4 border-[1px] md:border-2 text-[#fff] !border-[#fff] !rounded-lg md:!rounded-xl bg-blue-default">
              必須
            </div>
          </div>
          <div className="col-span-7">
            <textarea
              rows={5}
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              className="w-full p-1 sm:p-2"
            />
          </div>
        </div>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-5"></div>
          <div className="col-span-7 md:col-span-4 lg:col-span-3 text-left">
            <Button
              className="w-full h-[36px] sm:h-[42px] md:h-[48px] lg:h-[56px] xl:h-[72px] !text-xs md:!text-lg lg:!text-xl border-[1px] border-[#fff]"
              variant="contained"
              disabled={!isActive}
              onClick={createFeedback}
            >
              送信する
            </Button>
          </div>
        </div>
      </div>
      <Box className="!w-[200px] sm:!w-[270px] lg:!w-[450px]">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          onClose={handleClose}
          autoHideDuration={3000}
        >
          <Alert severity="success" onClose={handleClose} variant="filled">
            <Grid container display={"flex"} justifyContent={"start"}>
              <AlertTitle>成功</AlertTitle>
            </Grid>
            <Grid display={"flex"} justifyContent={"start"}>
              お問い合わせいただき、誠にありがとうございます。
            </Grid>
            <Grid display={"flex"} justifyContent={"start"}>
              お問い合わせ内容を受け付けました。
            </Grid>
            <Grid display={"flex"} justifyContent={"start"}>
              確認後、メールにてご返信いたしますので、しばらくお待ちください。
            </Grid>
          </Alert>
        </Snackbar>
      </Box>
    </CommonSection>
  );
};

export default QuestionSection;
