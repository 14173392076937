// src/theme.ts
import { createTheme, Theme } from "@mui/material/styles";

const theme: Theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2", // Customize primary color
    },
    secondary: {
      main: "#dc004e", // Customize secondary color
    },
  },
  typography: {
    fontFamily: "Roboto, Arial, sans-serif", // Customize typography
  },
  // Add other customizations here
});

export default theme;
