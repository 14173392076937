import Header from "../components/header";
import Footer from "../components/footer";
import ButtonNavigateTop from "../components/common/button/ButtonNavigateTop";

function PrivacyPage() {
  return (
    <main>
      <Header />
      <div className="main-wrapper p-8 sm:p-16 text-left">
        <div className="flex justify-center">
          <div className="text-xl">プライバシーポリシー</div>
        </div>
        <div className="flex justify-end">
          <div className="mt-4">最終更新日: 2024-08-02</div>
        </div>
        <div className="flex justify-start">
          <div className="mt-4">1. はじめに</div>
        </div>
        <div className="flex justify-start">
          <div className="mt-4">
            コエマモ（以下「本アプリ」）を利用いただきありがとうございます。本ポリシーは、本アプリがどのようにあなたの個人情報を収集、使用、保護するかについて説明します。ご利用前にご一読ください。
          </div>
        </div>
        <div className="flex justify-start">
          <div className="mt-4">2. 収集する情報</div>
        </div>
        <div className="flex justify-start">
          <div className="mt-4">
            本アプリでは以下の情報を収集することがあります。
          </div>
        </div>
        <div className="flex justify-start">
          <div className="mt-4 ml-8">
            個人情報:
            名前、生年月日、メールアドレス、電話番号など、ユーザーが自ら提供する情報。
          </div>
        </div>
        <div className="flex justify-start">
          <div className="ml-8">
            使用情報: アプリの利用状況、デバイスの情報、ログデータなど。
          </div>
        </div>
        <div className="flex justify-start">
          <div className="ml-8">
            位置情報: アプリの利用に関連して位置情報を収集する場合があります。
          </div>
        </div>
        <div className="flex justify-start">
          <div className="mt-4">3. 情報の使用目的</div>
        </div>
        <div className="flex justify-start">
          <div className="mt-4">収集した情報は以下の目的で使用されます。</div>
        </div>
        <div className="flex justify-start">
          <div className="mt-4 ml-8">サービスの提供と改善</div>
        </div>
        <div className="flex justify-start">
          <div className="ml-8">ユーザーサポートの提供</div>
        </div>
        <div className="flex justify-start">
          <div className="ml-8">お知らせや重要な情報の通知</div>
        </div>
        <div className="flex justify-start">
          <div className="ml-8">法的要件の遵守</div>
        </div>
        <div className="flex justify-start">
          <div className="mt-4">4. 情報の共有</div>
        </div>
        <div className="flex justify-start">
          <div className="mt-4">
            本アプリは以下の場合に限り、あなたの情報を第三者と共有することがあります。
          </div>
        </div>
        <div className="flex justify-start">
          <div className="mt-4 ml-8">法律に基づく要求や規制に従うため</div>
        </div>
        <div className="flex justify-start">
          <div className="ml-8">
            サービスの提供に必要な場合（例：支払い処理、カスタマーサポート）
          </div>
        </div>
        <div className="flex justify-start">
          <div className="ml-8">事業の譲渡や合併に関連して</div>
        </div>
        <div className="flex justify-start">
          <div className="mt-4">5. データの保護</div>
        </div>
        <div className="flex justify-start">
          <div className="mt-4">
            ユーザーの情報を保護するために合理的な技術的および組織的な措置を講じます。ただし、インターネッ
            ト上のデータ送信や保存に完全な安全性を保証することはできません。
          </div>
        </div>
        <div className="flex justify-start">
          <div className="mt-4">6. ユーザーの権利</div>
        </div>
        <div className="flex justify-start">
          <div className="mt-4">ユーザーは以下の権利を有します。</div>
        </div>
        <div className="flex justify-start">
          <div className="mt-4 ml-8">
            個人情報の確認、訂正、削除を求めることができます。
          </div>
        </div>
        <div className="flex justify-start">
          <div className="ml-8">データの利用を制限することができます。</div>
        </div>
        <div className="flex justify-start">
          <div className="mt-4">7. プライバシーポリシーの変更</div>
        </div>
        <div className="flex justify-start">
          <div className="mt-4">
            本ポリシーの内容は，利用者に通知することなく，変更することができるものとします。
          </div>
        </div>
        <div className="flex justify-start">
          <div className="mt-4">
            8.
            個人情報保護方針の内容および個人情報の取り扱いに関するお問い合わせ及び苦情の申し出先
          </div>
        </div>
        <div className="flex justify-start">
          <div className="mt-4 ml-4">株式会社 オルトロボ</div>
        </div>
        <div className="flex justify-start">
          <div className="ml-4">個人情報保護管理者 代表取締役 野下 主税</div>
        </div>
        <div className="flex justify-start">
          <div className="mt-4 ml-4">TEL：092-482-8550</div>
        </div>
        <div className="flex justify-start">
          <div className="ml-4">FAX：092-482-8551</div>
        </div>
        <div className="flex justify-start">
          <div className="mt-4">
            9．認定個人情報保護団体の名称及び苦情の解決の申出先
          </div>
        </div>
        <div className="flex justify-start">
          <div className="mt-4 ml-4">
            ※個人情報の取り扱いに関する苦情のみを受付けています。
          </div>
        </div>
        <div className="flex justify-start">
          <div className="mt-4 ml-4">一般財団法人日本情報経済社会推進協会</div>
        </div>
        <div className="flex justify-start">
          <div className="ml-4">認定個人情報保護団体事務局</div>
        </div>
        <div className="flex justify-start">
          <div className="mt-4 ml-4">
            〒106-0032 東京都港区六本木一丁目９番９号 六本木ファーストビル内
          </div>
        </div>
        <div className="flex justify-start">
          <div className="mt-4 ml-4">TEL：03-5860-7565</div>
        </div>
        <div className="flex justify-start">
          <div className="ml-4">FAX：0120-700-779</div>
        </div>
      </div>
      <Footer />
      <ButtonNavigateTop />
    </main>
  );
}

export default PrivacyPage;
