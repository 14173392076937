export const FaqList: { question: string, answer: string }[] = [
    {
        question: "他の防犯アプリと何が違うの？",
        answer: "類似サービスでは、防犯ブザーのみ、代理応答のみ、位置情報共有のみと、1つの機能に特化している場合が多いですが、「コエマモ」はこれら全てをまとめたオールインワンアプリです。\nコエマモひとつで、防犯対策、不安の軽減、緊急時の連絡が可能となります。\nさらに、声や応答のバリエーションも豊富に揃えており、様々なシチュエーションに対応できます。"
    },
    {
        question: "登録には料金がかかりますか？",
        answer: "無料でご登録・ご利用いただけます。\nただし、無料版ではご利用いただける機能に制限がございます。\n全機能をご利用されたい方は有料の「安心プレミアムプラン」がおすすめです。"
    },
    {
        question: "安心プレミアムプランのお試し期間中は本当に料金は発生しませんか？",
        answer: "はい、お試し期間中は、費用は一切かかりません。無料期間中でも、いつでも解約が可能です。\n安心プレミアムプランにご登録いただいてから30日間経過後は、¥300/月（年額プランの場合は¥3,000 / 年）が発生します。"
    },
    {
        question: "登録情報やプランの変更はできますか？",
        answer: "プラン変更：アプリの「マイページ > プラン変更」から変更可能です。\n登録情報変更：アプリの「マイページ > 登録情報」から変更可能です。"
    }
]