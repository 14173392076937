import React from "react";
import { FaqList } from "./constant";
import CommonSection from "../../common/section/CommonSection";

const CommentSection: React.FC = () => {
  return (
    <div className="-mt-8 md:-mt-0">
      <CommonSection id="comment" title="よくあるご質問">
        <div className="bg-white-dark">
          <div className="box rounded-[36px] bg-white">
            {FaqList.map((_, i) => (
              <div key={i} className="text-left">
                <div className="flex items-center gap-2 md:gap-6 mt-2 md:mt-4">
                  <img
                    src="img/24.png"
                    alt=""
                    className="w-[32px] md:w-[64px] h-[32px] md:h-[64px]"
                  ></img>
                  <h1 className="!text-xs sm:!text-lg md:!text-xl lg:!text-2xl !font-bold">
                    {_.question}
                  </h1>
                </div>
                <div className="md:ml-12 flex gap-2 md:gap-6 whitespace-break-spaces mt-2 md:mt-4">
                  <img
                    src="img/25.png"
                    alt=""
                    className="w-[32px] md:w-[64px] h-[32px] md:h-[64px]"
                  ></img>
                  <h1 className="!text-xs sm:!text-sm md:!text-lg lg:!text-xl !leading-4 md:!leading-8 lg:!leading-loose">
                    {_.answer}
                  </h1>
                </div>
              </div>
            ))}
          </div>
        </div>
      </CommonSection>
    </div>
  );
};

export default CommentSection;
