import React from "react";

const Service2Section: React.FC = () => {
  return (
    <div className="box border-[5px] bg-white border-blue-thin rounded-[36px]">
      <div className="flex items-center flex-col-reverse">
        <h1 className="!font-bold text-[#01579b] !text-[11px] sm:!text-lg md:!text-xl lg:!text-2xl xl:!text-4xl border-b-[6px] md:border-b-[8px] lg:border-b-[10px] xl:border-b-[12px] border-[#ffff00] !pt-2 md:!pt-8">
          不要な声掛けも防止
        </h1>
        <h1 className="!font-bold text-[#01579b] !text-[11px] sm:!text-lg md:!text-xl lg:!text-2xl xl:!text-4xl border-b-[6px] md:border-b-[8px] lg:border-b-[10px] xl:border-b-[12px] border-[#ffff00]">
          電話をしているフリで、ひとり帰宅の不安を払拭！
        </h1>
      </div>
      <div className="grid grid-cols-12 items-center">
        <div className="col-span-12 md:col-span-5">
          <img
            className="w-[150px] md:w-full mx-auto"
            src="img/22.png"
            alt=""
          />
        </div>
        <div className="col-span-12 md:col-span-7">
          <div className="flex justify-start">
            <h1 className="text-[10px] sm:!text-xs md:!text-sm lg:!text-lg xl:!text-2xl text-left !mt-1 sm:!mt-2 md:!mt-2 lg:!mt-4 xl:!mt-8">
              リアルな通話音声が流れてくるため、相槌をうつことで、
            </h1>
          </div>
          <div className="flex justify-start">
            <h1 className="text-[10px] sm:!text-xs md:!text-sm lg:!text-lg xl:!text-2xl text-left !mt-1 sm:!mt-1 md:!mt-2 xl:!mt-4">
              本当に電話をしているように見せかけます。
            </h1>
          </div>
          <div className="flex justify-start">
            <h1 className="text-[10px] sm:!text-xs md:!text-sm lg:!text-lg xl:!text-2xl text-left !mt-1 sm:!mt-1 md:!mt-2 xl:!mt-4">
              また、時間指定で着信音を鳴らす機能を活用し、
            </h1>
          </div>
          <div className="flex justify-start">
            <h1 className="text-[10px] sm:!text-xs md:!text-sm lg:!text-lg xl:!text-2xl text-left !mt-1 sm:!mt-1 md:!mt-2 xl:!mt-4">
              誰かから電話がかかってきたように演出することも◎
            </h1>
          </div>
          <div className="flex justify-start">
            <h1 className="!text-[11px] sm:!text-sm md:!text-lg lg:!text-xl xl:!text-3xl text-[#01579b] !mt-4 sm:!mt-6 md:!mt-8">
              利用シーンの例
            </h1>
          </div>
          <div className="flex justify-start">
            <h1 className="text-[10px] sm:!text-xs md:!text-sm lg:!text-lg xl:!text-2xl text-left !mt-1 sm:!mt-2 md:!mt-2 lg:!mt-4 xl:!mt-8">
              ・ひとりでの帰宅が心細いが、電話をかける相手がいない時
            </h1>
          </div>
          <div className="flex justify-start">
            <h1 className="text-[10px] sm:!text-xs md:!text-sm lg:!text-lg xl:!text-2xl text-left !mt-1 sm:!mt-1 md:!mt-2 xl:!mt-4">
              ・騒がしい街を歩く際、不要な声掛けに引っかかりたくない時
            </h1>
          </div>
          <div className="flex justify-start">
            <h1 className="text-[10px] sm:!text-xs md:!text-sm lg:!text-lg xl:!text-2xl text-left !mt-1 sm:!mt-1 md:!mt-2 xl:!mt-4">
              ・オートロック玄関の前やエレベーター前など、被りたくないタイミングで人と被った時
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service2Section;
