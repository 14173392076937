const CommonSection: React.FC<{
  id?: string;
  title: string;
  children: React.ReactNode | undefined;
}> = ({ id, title, children }) => {
  return (
    <section className="main-wrapper">
      <div id={id} className="box bg-white-dark">
        <div className="">
          <div className="flex justify-center">
            <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-blue-default border-b-1 md:border-b-2 border-blue-default mb-4 md:mb-8">
              {title}
            </h1>
          </div>
          {children}
        </div>
      </div>
    </section>
  );
};

export default CommonSection;
