import { ArrowUpwardOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";

const ButtonNavigateTop: React.FC<{}> = () => {
  const [showScroll, setShowScroll] = useState(false);

  const checkScroll = () => {
    const viewportHeight = window.innerHeight;
    setShowScroll(window.pageYOffset > viewportHeight);
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);

  if (!showScroll) return null;

  return (
    <Button
      variant="contained"
      sx={{
        zIndex: 99,
        width: 64,
        height: 64,
        position: "fixed",
        bottom: 16,
        right: 32,
        borderRadius: "50%",
      }}
      onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
    >
      <ArrowUpwardOutlined />
    </Button>
  );
};

export default ButtonNavigateTop;
