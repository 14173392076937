import { Fade, Slide, Zoom } from "@mui/material";
import React, { useEffect, useState } from "react";

const IntroSection: React.FC = () => {
  const [checked, setChecked] = useState(false);
  const containerRef = React.useRef<HTMLElement>(null);

  useEffect(() => {
    // Delay the first toggle to allow initial animation
    const timeoutId = setTimeout(() => {
      setChecked(true);
    }, 1500); // Time after which the first animation will complete
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const goTo = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <section className="main-wrapper">
      <div className="h-[440px] sm:h-[600px] md:h-[360px] lg:h-[400px] xl:h-[480px] bg-green-light content-center">
        <div className="grid grid-cols-12">
          <div className="col-span-12 md:col-span-5">
            <div className="flex justify-center">
              <Slide in={checked} container={containerRef.current}>
                <img
                  src="img/1.png"
                  className="w-[60px] sm:w-[60px] md:w-[80px] lg:w-[100px] xl:w-[120px] h-[60px] sm:h-[60px] md:h-[80px] lg:h-[100px] xl:h-[120px]"
                  alt="icon"
                ></img>
              </Slide>
            </div>
            <div className="flex justify-center">
              <Fade in={checked}>
                <div className="text-white text-lg sm:text-lg md:text-lg lg:text-xl xl:text-3xl">
                  ＼声で守る、あなたの防犯パートナー／
                </div>
              </Fade>
            </div>
            <div className="flex justify-center mt-4 md:mt-8">
              <Fade in={checked}>
                <img
                  className="w-auto h-[50px] sm:h-[75px] md:h-auto"
                  src="img/28.png"
                  alt="banner"
                ></img>
              </Fade>
            </div>
            <div className="flex justify-center md:hidden">
              <Fade
                in={checked}
                style={{ transitionDelay: checked ? "1000ms" : "0ms" }}
              >
                <img
                  src="img/top/11.png"
                  alt="11.png"
                  className="p-4 mr-2"
                ></img>
              </Fade>
            </div>
            <div className="grid grid-cols-12 gap-4 md:gap-8 mb-4 md:mb-8 md:mt-8">
              <div className="col-start-2 sm:col-start-3 col-span-5 sm:col-span-4">
                <Zoom in={checked}>
                  <img
                    src="img/2.png"
                    alt="appStore"
                    className="cursor-pointer"
                    onClick={() =>
                      goTo(
                        "https://apps.apple.com/us/app/%E3%82%B3%E3%82%A8%E3%83%9E%E3%83%A2-%E9%98%B2%E7%8A%AF%E3%82%A2%E3%83%97%E3%83%AA/id6566182778"
                      )
                    }
                  ></img>
                </Zoom>
              </div>
              <div className="col-end-12 sm:col-end-11 col-span-5 sm:col-span-4">
                <Zoom
                  in={checked}
                  style={{ transitionDelay: checked ? "500ms" : "0ms" }}
                >
                  <img
                    src="img/3.png"
                    alt="googlePlay"
                    className="cursor-pointer"
                    onClick={() =>
                      goTo(
                        "https://play.google.com/store/apps/details?id=jp.co.altrobo.koemamo"
                      )
                    }
                  ></img>
                </Zoom>
              </div>
            </div>
          </div>
          <div className="hidden md:block col-span-0 md:col-span-7 content-center">
            <div className="flex justify-center">
              <Fade
                in={checked}
                style={{ transitionDelay: checked ? "1000ms" : "0ms" }}
              >
                <div className="p-8">
                  <img src="img/top/11.png" alt="11.png"></img>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IntroSection;
