import React from "react";

const Service4Section: React.FC = () => {
  return (
    <div className="h-full box border-[5px] bg-white border-blue-thin rounded-[36px]">
      <div className="flex items-center flex-col-reverse">
        <h1 className="!font-bold text-[#01579b] !text-[11px] sm:!text-lg md:!text-xl lg:!text-2xl xl:!text-4xl border-b-[6px] md:border-b-[8px] lg:border-b-[10px] xl:border-b-[12px] border-[#ffff00]">
          大音量で周囲に警告
        </h1>
      </div>
      <div className="flex justify-center items-center">
        <img className="w-[150px] md:w-[300px]" src="img/16.png" alt="" />
      </div>
      <div className="grid grid-cols-12">
        <div className="col-span-12">
          <div className="flex justify-start">
            <h1 className="text-[10px] sm:!text-xs md:!text-sm lg:!text-lg xl:!text-2xl text-left !mt-1 sm:!mt-2 md:!mt-2 lg:!mt-4 xl:!mt-8">
              緊急時に大きな声をだすことは困難なもの。
            </h1>
          </div>
          <div className="flex justify-start">
            <h1 className="text-[10px] sm:!text-xs md:!text-sm lg:!text-lg xl:!text-2xl text-left !mt-1 sm:!mt-1 md:!mt-2 xl:!mt-4">
              そんなときでも、スマホを使って簡単に周囲に
            </h1>
          </div>
          <div className="flex justify-start">
            <h1 className="text-[10px] sm:!text-xs md:!text-sm lg:!text-lg xl:!text-2xl text-left !mt-1 sm:!mt-1 md:!mt-2 xl:!mt-4">
              SOSを知らせることができます。
            </h1>
          </div>
          <div className="flex justify-start">
            <h1 className="!text-[11px] sm:!text-sm md:!text-lg lg:!text-xl xl:!text-3xl text-[#01579b] !mt-4 sm:!mt-6 md:!mt-8">
              利用シーンの例
            </h1>
          </div>
          <div className="flex justify-start">
            <h1 className="text-[10px] sm:!text-xs md:!text-sm lg:!text-lg xl:!text-2xl text-left !mt-1 sm:!mt-2 md:!mt-2 lg:!mt-4 xl:!mt-8 sm:tracking-tight">
              ・普段はブザーを持たないが、防犯対策がしたい時
            </h1>
          </div>
          <div className="flex justify-start">
            <h1 className="text-[10px] sm:!text-xs md:!text-sm lg:!text-lg xl:!text-2xl text-left !mt-1 sm:!mt-1 md:!mt-2 xl:!mt-4 sm:tracking-tight">
              ・お子さんの外出時に危険から守るものがほしい時
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service4Section;
