import React from "react";

const Service3Section: React.FC = () => {
  return (
    <div className="box border-[5px] bg-white border-blue-thin rounded-[36px]">
      <div className="flex items-center flex-col-reverse">
        <h1 className="!font-bold text-[#01579b] !text-[11px] sm:!text-lg md:!text-xl lg:!text-2xl xl:!text-4xl border-b-[6px] md:border-b-[8px] lg:border-b-[10px] xl:border-b-[12px] border-[#ffff00]">
          大切な人に、緊急事態をすぐに知らせる
        </h1>
      </div>
      <div className="grid grid-cols-12 items-center">
        <div className="col-span-12 md:col-span-7">
          <div className="flex justify-start">
            <h1 className="text-[10px] sm:!text-xs md:!text-sm lg:!text-lg xl:!text-2xl text-left !mt-4 md:!mt-8 lg:!mt-16">
              パートナーや家族など、事前設定した相手に対して、
            </h1>
          </div>
          <div className="flex justify-start">
            <h1 className="text-[10px] sm:!text-xs md:!text-sm lg:!text-lg xl:!text-2xl text-left !mt-1 sm:!mt-1 md:!mt-2 xl:!mt-4">
              緊急時に位置情報付きの通知を送信します。
            </h1>
          </div>
          <div className="flex justify-start">
            <h1 className="text-[10px] sm:!text-xs md:!text-sm lg:!text-lg xl:!text-2xl text-left !mt-1 sm:!mt-1 md:!mt-2 xl:!mt-4">
              また、ボタンひとつで緊急事態を知らせると同時に、
            </h1>
          </div>
          <div className="flex justify-start">
            <h1 className="text-[10px] sm:!text-xs md:!text-sm lg:!text-lg xl:!text-2xl text-left !mt-1 sm:!mt-1 md:!mt-2 xl:!mt-4">
              特定の相手に電話で助けを求めることができます。
            </h1>
          </div>
          <div className="flex justify-start">
            <h1 className="!text-[11px] sm:!text-sm md:!text-lg lg:!text-xl xl:!text-3xl text-[#01579b] !mt-4 sm:!mt-6 md:!mt-8">
              利用シーンの例
            </h1>
          </div>
          <div className="flex justify-start">
            <h1 className="text-[10px] sm:!text-xs md:!text-sm lg:!text-lg xl:!text-2xl text-left !mt-1 sm:!mt-2 md:!mt-2 lg:!mt-4 xl:!mt-8">
              ・危険な状況に遭遇し、大切な人に事態を早く知らせたい時
            </h1>
          </div>
          <div className="flex justify-start">
            <h1 className="text-[10px] sm:!text-xs md:!text-sm lg:!text-lg xl:!text-2xl text-left !mt-1 sm:!mt-1 md:!mt-2 xl:!mt-4">
              ・緊急時に警察にすぐに連絡したい時
            </h1>
          </div>
        </div>
        <div className="col-span-12 md:col-span-5">
          <img
            className="w-[150px] md:w-full mx-auto"
            src="img/15.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Service3Section;
