import React from "react";
import { ImageSection } from "./imageSection";
import CommonSection from "../../common/section/CommonSection";

const LifeSection: React.FC = () => {
  return (
    <CommonSection id="life-section" title="⽇常⽣活に潜む不安や悩み">
      <div className="life-section">
        <ImageSection />
        <div className="mt-10 box border-[5px] rounded-[36px] border-blue-thin bg-white">
          <div className="flex items-center justify-center text-brown-default">
            <img
              src="img/17.png"
              className="w-[30px] sm:w-[60px] md:w-[100px] object-contain"
              alt=""
            />
            <div>
              <div className="flex flex-wrap justify-center">
                <h1 className="sm:tracking-[8px] md:tracking-[12px] font-bold text-base sm:text-xl md:text-2xl">{`これらのお悩み、`}</h1>
                <h1 className="sm:tracking-[8px] md:tracking-[12px] font-bold text-base sm:text-xl md:text-2xl">{`コエマモが解決します 。`}</h1>
              </div>
              <h5 className="sm:tracking-[8px] md:tracking-[10px] text-sm sm:text-md md:text-xl pt-5 pb-5">{`あなたを守る５つの安心機能`}</h5>
            </div>
            <img
              src="img/18.png"
              className="w-[30px] sm:w-[60px] md:w-[100px] object-contain"
              alt=""
            />
          </div>
          <div className="image-items">
            {[
              {
                image: "img/7.png",
                label: "代理応答",
              },
              {
                image: "img/8.png",
                label: "フェイク通話",
              },
              {
                image: "img/9.png",
                label: "緊急通知",
              },
              {
                image: "img/20.png",
                label: "防犯ブザー",
              },
              {
                image: "img/10.png",
                label: "痴漢対策",
              },
            ].map((_, i) => (
              <div key={i} className="item">
                <img className="img" src={_.image} alt="" />
                <span className="label">{_.label}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </CommonSection>
  );
};

export default LifeSection;
