import Header from "../components/header";
import Footer from "../components/footer";
import IntroSection from "../components/sections/intro-section";
import KoemamoSection from "../components/sections/koemamo-section";
import LifeSection from "../components/sections/life-section";
import ServiceSection from "../components/sections/service-section";
import PlanSection from "../components/sections/plan-section";
import CommentSection from "../components/sections/comment-section";
import QuestionSection from "../components/sections/question-section";
import ButtonNavigateTop from "../components/common/button/ButtonNavigateTop";

function LandingPage() {
  return (
    <main>
      <Header />
      <IntroSection />
      <div className="main-wrapper h-6 sm:h-8 md:h-10 lg:h-12"></div>
      <KoemamoSection />
      <LifeSection />
      <ServiceSection />
      <PlanSection />
      <CommentSection />
      <QuestionSection />
      <Footer />
      <ButtonNavigateTop />
    </main>
  );
}

export default LandingPage;
