import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

console.log({ baseURL });

const postData = async (endpoint: string, data: any) => {
  try {
    const response = await axios.post(`${baseURL}/${endpoint}`, data);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export { postData };
