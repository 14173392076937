import React from "react";
import Service1Section from "./service1-section";
import Service2Section from "./service2-section";
import Service3Section from "./service3-section";
import Service4Section from "./service4-section";
import Service5Section from "./service5-section";
import CommonSection from "../../common/section/CommonSection";
import CommonServiceSection from "../../common/section/CommonServiceSection";

const ServiceSection: React.FC = () => {
  return (
    <CommonSection id="service" title="サービスの特徴">
      <div className="">
        <section>
          {/* 代理応答 */}
          <CommonServiceSection title="代理応答" numberLabel="01">
            <Service1Section />
          </CommonServiceSection>
        </section>
        <section>
          {/* フェイク通話 */}
          <CommonServiceSection title="フェイク通話" numberLabel="02">
            <Service2Section />
          </CommonServiceSection>
        </section>
        <section>
          {/* 緊急通知 */}
          <CommonServiceSection title="緊急通知" numberLabel="03">
            <Service3Section />
          </CommonServiceSection>
        </section>
        <section className="">
          <div className="grid md:grid-cols-2 gap-x-4 md:gap-x-8 h-full">
            <div className="h-full">
              {/* 防犯ブザー */}
              <CommonServiceSection title="防犯ブザー" numberLabel="04">
                <Service4Section />
              </CommonServiceSection>
            </div>
            <div className="h-full">
              {/* 痴漢対策 */}
              <CommonServiceSection title="痴漢対策" numberLabel="05">
                <Service5Section />
              </CommonServiceSection>
            </div>
          </div>
        </section>
      </div>
    </CommonSection>
  );
};

export default ServiceSection;
