import React from "react";
import CommonSection from "../../common/section/CommonSection";

const KoemamoSection: React.FC = () => {
  return (
    <CommonSection id="koemamo" title="About">
      <div>
        <h1 className="text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl font-bold text-brown-default whitespace-break-spaces leading-8 sm:leading-10 md:!leading-[60px]">
          {"コエマモは、あなたの安心を声で守る\n防犯に特化したアプリです。"}
        </h1>
      </div>
      <div className="mt-4 md:mt-8">
        <h1 className="text-xs sm:text-sm md:text-lg lg:text-xl xl:text-2xl !leading-6 sm:!leading-8 md:!leading-10 xl:!leading-[50px] text-center whitespace-break-spaces">
          <div className="hidden sm:block">{`常に持ち歩くスマホの中に、日常生活で感じる不安や犯罪のリスクを減らし、\nあなたの安心を支えるお守りのような存在があってほしい。\nそんな想いから、防犯対策と緊急時の対策を一つにまとめた\n防犯スマホアプリ「コエマモ」が誕生しました。`}</div>
          <div className="sm:hidden">{`常に持ち歩くスマホの中に、\n日常生活で感じる不安や犯罪のリスクを減らし、\nあなたの安心を支える\nお守りのような存在があってほしい。\nそんな想いから、\n防犯対策と緊急時の対策を一つにまとめた\n防犯スマホアプリ「コエマモ」が誕生しました。`}</div>
        </h1>
      </div>
    </CommonSection>
  );
};

export default KoemamoSection;
