import { Box } from "@mui/material";
import "./style.css";

export const ImageSection = () => {
  return (
    <Box className="life-section-thumbnail">
      <div className="grid grid-cols-12">
        {[
          "/img/life/13.png",
          "/img/life/14.png",
          "/img/life/15.png",
          "/img/life/16.png",
          "/img/life/17.png",
          "/img/life/18.png"
        ].map((_, i) =>
          <div key={i} className={"col-span-6 sm:col-span-4" + ((i % 2) ? " pt-16 sm:pt-0" : "")}>
            <img src={_} alt="" />
          </div>
        )}
      </div>
    </Box>
  );
};
