const CommonServiceSection: React.FC<{
  title: string;
  numberLabel: string;
  children: React.ReactNode | undefined;
}> = ({ title, numberLabel, children }) => {
  return (
    <section className="h-full pt-16 sm:pt-24 md:pt-40 relative">
      {children}
      <div className="absolute top-4 sm:top-8 md:top-16 left-0 right-0 ">
        <div className="flex">
          <div className="text-left">
            <button className="text-[10px] sm:text-lg md:text-xl xl:text-2xl w-[100px] md:w-[160px] lg:w-[200px] h-[30px] md:h-[48px] lg:h-[60px] rounded-full bg-[#0277bd] text-[#f8f8f8]">
              {numberLabel} {title}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CommonServiceSection;
